<template>
	<errorContainer :error="erreur">
		<div v-if="Users">
			<div class="w-auto">
				<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
					{{ this.FormMSG(10000, 'Crew setup') }}
				</h1>
			</div>
			<div :class="`${$screen.width <= 576 ? 'my-project' : ''}`">
				<transition name="slide">
					<b-tabs v-model="tabs.activeTabs" @activate-tab="(n, p, e) => onTabClicked(n)">
						<b-tab lazy v-if="canSeeApprove && !isForProjects && !isForGreenshotUser && useDoc" :title="FormMSG(1600, 'Approve')">
							<ValidateDocuments ref="validate-documents" />
						</b-tab>
						<b-tab v-if="canSeeUserSetting || (isDev && isForProjects) || (isDev && isForGreenshotUser)" lazy :title="FormMSG(1, 'Users')">
							<b-card no-body class="card-border-blue-light form p-4">
								<b-row v-if="Users && $screen.width >= 992" class="form align-items-center">
									<b-col sm="12" md="12" lg="6" xl="6" class="pb-3">
										<b-input-group v-if="$screen.width >= 992">
											<filter-input-search @filtered-users="filteredUsers" @handle-init-filter="onHandleInitFilter" />
										</b-input-group>
										<!--HERE SOME CHANGES-->
									</b-col>
									<b-col v-if="isForProject" sm="4" md="4" lg="4" xl="4" class="pb-3">
										<b-row>
											<b-col sm="2" md="2"> {{ FormMSG(1715, 'Show') }}</b-col>
											<b-col sm="4" md="4">
												<b-row>
													<radio-button v-model="usersListDisplayedBy" inputId="users" name="users" value="users" />
													<label for="users" class="ml-2">{{ FormMSG(279, 'By Users') }}</label>
												</b-row>
											</b-col>
											<b-col sm="4" md="4">
												<b-row>
													<radio-button v-model="usersListDisplayedBy" inputId="contract" name="contract" value="contract" />
													<label for="contract" class="ml-2">
														{{ FormMSG(278, 'By Contract') }}
													</label>
												</b-row>
											</b-col>
										</b-row>
										<!--HERE SOME CHANGES-->
									</b-col>
									<b-col class="pb-3 d-flex justify-content-end">
										<b-button v-if="useDpae" variant="primary" @click="reloadData">
											<component :is="getLucideIcon('RefreshCw')" :size="16" />
											<span class="ml-1"> {{ FormMSG(110, 'Retrieve Data') }}</span>
										</b-button>
									</b-col>
								</b-row>
								<CardListBuilder
									v-if="$screen.width < 992"
									:items="Users"
									:fields="Mobilefields"
									:omittedFields="['isProd', 'isAdmin', 'validationLevel', 'pict', 'canAssign', 'Screen Access']"
								>
									<template slot="body" slot-scope="data">
										<b-row style="margin-top: 20px">
											<b-col md="12">
												<b-form-select
													v-model="data.item.validationLevel"
													:options="optionsForValidationLevel"
													@change="manageValidationChange(data.item)"
													id="valLevel"
													size="md"
												/>
											</b-col>
										</b-row>
										<b-row class="lh-22" style="margin-top: 20px; flex-direction: row">
											<b-col xs="6">
												<b class="mb-2">{{ FormMSG(1000, 'Screen Access') }}</b>
												<b-col md="12" class="mb-3">
													<b-form-checkbox
														size="sm"
														v-model="data.item.canManageCrewList"
														:value="true"
														:unchecked-value="false"
														@change="handleAccessLevel('canManageCrewList', $event, data.item, screenAccess)"
													>
														{{ FormMSG(2970, 'Manage crew list') }}
													</b-form-checkbox>
												</b-col>
												<b-col md="12" class="mb-3">
													<b-form-checkbox
														size="sm"
														v-model="data.item.canManageDocuments"
														:value="true"
														:unchecked-value="false"
														@change="handleAccessLevel('canManageDocuments', $event, data.item, screenAccess)"
													>
														{{ FormMSG(2971, 'Manage documents') }}
													</b-form-checkbox>
												</b-col>
												<b-col md="12" class="mb-3">
													<b-form-checkbox
														size="sm"
														v-model="data.item.canManageDocumentPackage"
														:value="true"
														:unchecked-value="false"
														@change="handleAccessLevel('canManageDocumentPackage', $event, data.item, screenAccess)"
													>
														{{ FormMSG(2440, 'Manage document package') }}
													</b-form-checkbox>
												</b-col>
												<b-col md="12" class="mb-3">
													<b-form-checkbox
														size="sm"
														v-model="data.item.canManageCallSheets"
														:value="true"
														:unchecked-value="false"
														@change="handleAccessLevel('canManageCallSheets', $event, data.item, screenAccess)"
													>
														{{ FormMSG(2450, 'Manage call sheets') }}
													</b-form-checkbox>
												</b-col>
												<b-col md="12" class="mb-3">
													<b-form-checkbox
														size="sm"
														v-model="data.item.canManageAccommodations"
														:value="true"
														:unchecked-value="false"
														@change="handleAccessLevel('canManageAccommodations', $event, data.item, screenAccess)"
													>
														{{ FormMSG(2973, 'Manage accomodations') }}
													</b-form-checkbox>
												</b-col>
												<b-col md="12" class="mb-3">
													<b-form-checkbox
														size="sm"
														v-model="data.item.canManageLocations"
														:value="true"
														v-if="useLoc"
														:unchecked-value="false"
														@change="handleAccessLevel('canManageLocations', $event, data.item, screenAccess)"
													>
														{{ FormMSG(2449, 'Manage locations') }}
													</b-form-checkbox>
												</b-col>
												<b-col md="12" class="mb-3">
													<b-form-checkbox
														size="sm"
														v-model="data.item.canExportImport"
														:value="true"
														:unchecked-value="false"
														@change="handleAccessLevel('canExportImport', $event, data.item, screenAccess)"
													>
														{{ FormMSG(2455, 'Can import/export') }}
													</b-form-checkbox>
												</b-col>
												<b-col md="12" class="mb-3">
													<b-form-checkbox
														size="sm"
														v-model="data.item.canViewGlobalBudget"
														:value="true"
														:unchecked-value="false"
														@change="handleAccessLevel('canViewGlobalBudget', $event, data.item, screenAccess)"
													>
														{{ FormMSG(24430, 'View global budget') }}
													</b-form-checkbox>
												</b-col>
												<b-col md="12" class="mb-3">
													<b-form-checkbox
														size="sm"
														v-model="data.item.canAccessGreenReporting"
														:value="true"
														:unchecked-value="false"
														@change="handleAccessLevel('canAccessGreenReporting', $event, data.item, screenAccess)"
													>
														{{ FormMSG(24560, 'View Green reporting screens') }}
													</b-form-checkbox>
												</b-col>
												<b-col md="12" class="mb-3">
													<b-form-checkbox
														size="sm"
														v-model="data.item.canManageCarbonRemoval"
														:value="true"
														:unchecked-value="false"
														@change="handleAccessLevel('canManageCarbonRemoval', $event, data.item, screenAccess)"
													>
														{{ FormMSG(24480, 'Manage carbon removal') }}
													</b-form-checkbox>
												</b-col>
												<b-col md="12" class="mb-3">
													<b-form-checkbox
														size="sm"
														v-model="data.item.canManageTipOfTheDay"
														:value="true"
														:unchecked-value="false"
														@change="handleAccessLevel('canManageTipOfTheDay', $event, data.item, screenAccess)"
													>
														{{ FormMSG(24405, 'Manage tip of the day') }}
													</b-form-checkbox>
												</b-col>
												<b-col md="12" class="mb-3">
													<b-form-checkbox
														size="sm"
														v-model="data.item.canAccessConfig"
														:value="true"
														:unchecked-value="false"
														@change="handleAccessLevel('canAccessConfig', $event, data.item, screenAccess)"
													>
														{{ FormMSG(246, 'Can access config') }}
													</b-form-checkbox>
												</b-col>
												<b-col v-if="isPme" md="12" class="mb-3">
													<b-form-checkbox
														size="sm"
														v-model="data.item.canAssign"
														:value="true"
														:unchecked-value="false"
														@change="handleAccessLevel(canAssign, $event, data.item, screenAccess)"
													>
														{{ FormMSG(30, 'Planning Manager') }}
													</b-form-checkbox>
												</b-col>
												<b-col v-if="!isForProject" md="12" class="mb-3">
													<b-form-checkbox
														size="sm"
														v-model="data.item.isDev"
														:value="true"
														:unchecked-value="false"
														@change="manageIsDevChange($event, data.item)"
													>
														{{ FormMSG(42, 'View TheGreenShot Menu') }}
													</b-form-checkbox>
												</b-col>
												<b-col v-if="!isForProject" md="12" class="mb-3">
													<b-form-checkbox
														size="sm"
														v-model="data.item.isTranslator"
														:value="true"
														:unchecked-value="false"
														@change="manageIsTranslatorChange($event, data.item)"
													>
														{{ FormMSG(43, 'Translator') }}
													</b-form-checkbox>
												</b-col>
											</b-col>
											<b-col xs="6">
												<b class="mb-2">{{ FormMSG(1001, 'Role') }}</b>
												<b-form-checkbox
													size="sm"
													class="mb-3"
													v-model="data.item.gateKeeper"
													@change="handleAccessLevel(gateKeeper, $event, data.item, screenAccess)"
												>
													{{ FormMSG(266, 'Gate Keeper') }}
												</b-form-checkbox>
												<b-form-checkbox
													size="sm"
													class="mb-3"
													v-model="data.item.productionAccountant"
													@change="handleAccessLevel(productionAccountant, $event, data.item, screenAccess)"
												>
													{{ FormMSG(261, 'Production Account') }}
												</b-form-checkbox>
												<b-form-checkbox size="sm" class="mb-3" v-model="data.item.upm" @change="manageUpmChange($event, data.item)">
													{{ FormMSG(262, 'UPM') }}
												</b-form-checkbox>
												<b-form-checkbox
													size="sm"
													class="mb-3"
													v-model="data.item.lineProducer"
													@change="handleAccessLevel(lineProducer, $event, data.item, screenAccess)"
												>
													{{ FormMSG(263, 'Line Producer') }}
												</b-form-checkbox>
												<b-form-checkbox
													size="sm"
													class="mb-3"
													v-model="data.item.studio"
													@change="manageStudioChange($event, data.item)"
												>
													{{ FormMSG(265, 'Studio') }}
												</b-form-checkbox>
											</b-col>
										</b-row>
									</template>
									<template slot="actions" slot-scope="data">
										<b-button
											variant="success"
											size="sm"
											@click="sendEmail(data.item)"
											class="mr-1 btn bg-transparent border-0"
											title="Send email"
										>
											<component :is="getLucideIcon(ICONS.MAIL.name)" :color="ICONS.MAIL.color" :size="20" />
										</b-button>
										<b-button
											variant="success"
											:disabled="data.item.phone.length < 1"
											size="sm"
											@click="callPhone(data.item)"
											class="mr-1 btn bg-transparent border-0"
											title="Call by phone"
										>
											<component :is="getLucideIcon(ICONS.PHONE.name)" :color="ICONS.PHONE.color" :size="20" />
										</b-button>
										<b-button
											variant="success"
											:disabled="data.item.picture.length < 1"
											size="sm"
											class="btn bg-transparent border-0"
											@click="showPicture(data.item.picture)"
											title="View picture"
										>
											<component :is="getLucideIcon(ICONS.PICTURE.name)" :color="ICONS.PICTURE.color" :size="20" />
										</b-button>
										<b-button
											class="mr-1 btn bg-transparent border-0"
											variant="success"
											size="sm"
											@click="downloadInfosheet(data.item.id)"
											title="Download infosheet"
										>
											<component :is="getLucideIcon(ICONS.EYE.name)" color="#225CBD" :size="20" />
										</b-button>
										<b-button
											variant="primary"
											size="sm"
											@click="rowClicked(data.item)"
											title="Update user"
											class="btn bg-transparent border-0"
										>
											<component :is="getLucideIcon(ICONS.EDIT.name)" :color="ICONS.EDIT.color" :size="20" />
										</b-button>

										<b-button
											size="sm"
											@click="eraseUser(data.item)"
											:disabled="isDisabled(data)"
											title="Delete user"
											class="btn bg-transparent border-0"
										>
											<delete color="tomato" :size="20" />
										</b-button>
									</template>
								</CardListBuilder>
								<b-table
									v-if="$screen.width >= 992"
									:hover="hover"
									responsive
									selectable
									:selected-variant="selectedColor"
									:select-mode="selectMode"
									:items="Users"
									:fields="fields"
									:current-page="currentPage"
									:per-page="perPage"
									sticky-header="700px"
									@row-clicked="rowClicked"
									:head-variant="hv"
									bordered
									small
									ref="table-users"
									:busy="isBusy"
									id="containerUsers"
									:empty-text="FormMSG(250, 'No data found')"
									show-empty
								>
									<template #table-busy>
										<div class="d-flex justify-content-center align-items-center">
											<b-spinner class="align-middle" small></b-spinner>
											<strong class="ml-2">{{ FormMSG(353, 'Loading') }}...</strong>
										</div>
									</template>
									<template #head(actionSelected)="">
										<b-form-checkbox v-model="isAllItemSelected" @change="selectAllItem" size="lg" />
									</template>
									<template #cell(actionSelected)="data">
										<b-form-checkbox
											v-if="isSendableStatusDpae(data.item.contractDpaeStatus) && !checkIfThereIsInvalidFields(data.item)"
											v-model="data.item.isSelected"
											@change="selectItem(data.item)"
											size="lg"
										/>
									</template>
									<template #cell(isMandatoryFieldComplete)="data">
										<component
											style="color: #f5c567"
											v-if="!data.item.isMandatoryFieldComplete"
											:is="getLucideIcon('AlertTriangle')"
											:size="16"
										/>
										{{ data.item.isMandatoryFieldComplete ? FormMSG(370, 'Complete') : FormMSG(371, 'Missing Info') }}
									</template>
									<template v-slot:cell(functionName)="data">
										{{ data.item.customFunctionName ? data.item.customFunctionName : data.item.functionName }}
									</template>
									<template v-if="useDpae" v-slot:cell(contractDpaeStatus)="data">
										<DpaeTooltip
											:contractDpaeStatus="data.item.contractDpaeStatus"
											:data="data.item"
											:user="data.item"
											:target="usersListDisplayedBy === 'users' ? data.item.id : data.item.contractId"
											for-user
										/>
										<div :id="'tooltip-target-' + (usersListDisplayedBy === 'users' ? data.item.id : data.item.contractId)">
											<div>
												<component
													:is="getLucideIcon(getDpaeStatusIcon(data.item.contractDpaeStatus))"
													:size="20"
													:stroke-width="2"
													:class="getDpaeStatusClass(data.item.contractDpaeStatus)"
													class="mr-2"
												/>
												<span :class="data.item.contractDpaeStatus === 1 ? getDpaeStatusClass(data.item.contractDpaeStatus) : ''">{{
													getDpaeStatusText(data.item.contractDpaeStatus)
												}}</span>
											</div>
											<div class="ml-4 missing-info-text">
												<span
													v-if="data.item.contractDpaeStatus === DPAE_STATUS.NOT_SENT && checkIfThereIsInvalidFields(data.item)"
													class="text-center"
												>
													{{ FormMSG(999, 'Missing Info') }}
												</span>
											</div>
										</div>
									</template>
									<!-- <template slot="pict" slot-scope="data"> -->
									<template v-slot:cell(pict)="data">
										<b-dropdown
											no-caret
											dropleft
											offset="25"
											variant="none"
											style="border-right: 0px !important; padding-top: 0px !important"
										>
											<template #button-content>
												<more-vertical />
											</template>
											<b-dropdown-item-button @click="sendEmail(data.item)"
												><component :is="getLucideIcon(ICONS.MAIL.name)" :color="ICONS.MAIL.color" :size="20" /> &nbsp;
												<b>{{ FormMSG(2000, 'Send email') }}</b></b-dropdown-item-button
											>
											<b-dropdown-item-button :disabled="data.item.phone.length < 1" @click="callPhone(data.item)"
												><component :is="getLucideIcon(ICONS.PHONE.name)" :color="ICONS.PHONE.color" :size="20" /> &nbsp;
												<b>{{ FormMSG(2001, 'Call by phone') }}</b></b-dropdown-item-button
											>
											<b-dropdown-item-button :disabled="data.item.picture.length < 1" @click="showPicture(data.item.picture)"
												><component :is="getLucideIcon(ICONS.PICTURE.name)" :color="ICONS.PICTURE.color" :size="20" /> &nbsp;
												<b>{{ FormMSG(2002, 'View picture') }}</b></b-dropdown-item-button
											>
											<b-dropdown-item-button @click="downloadInfosheet(data.item.id)"
												><component :is="getLucideIcon('Download')" color="#225CBD" :size="20" /> &nbsp;
												<b>{{ FormMSG(2003, 'Download infosheet') }}</b></b-dropdown-item-button
											>
											<b-dropdown-item-button @click="eraseUser(data.item)" :disabled="isDisabled(data)">
												<delete color="tomato" /> &nbsp; <b>{{ FormMSG(2004, 'Delete user') }}</b></b-dropdown-item-button
											>
										</b-dropdown>
									</template>
									<!-- slot-scope="data"  -->
									<template slot="HEAD_isProd"> Prod Rights </template>
									<template v-slot:cell(Impersonate)="data">
										<b-button class="btn bg-transparent border-0" variant="primary" size="sm" @click="becomeUser(data.item)">
											<component :is="getLucideIcon(ICONS.EYE.name)" color="#225CBD" :size="20" />
										</b-button>
									</template>
									<template v-slot:cell(delUser)="data">
										<b-button v-if="myUserId != data.item.id" variant="none" size="sm" @click="deleteUser(data.item)">
											<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" />
										</b-button>
									</template>
									<template v-slot:cell(isDev)="data">
										<b-form-checkbox size="lg" v-model="data.item.isDev" @change="manageIsDevChange($event, data.item)"> </b-form-checkbox>
									</template>
									<template v-slot:cell(isTranslator)="data">
										<b-form-checkbox size="lg" v-model="data.item.isTranslator" @change="manageIsTranslatorChange($event, data.item)">
										</b-form-checkbox>
									</template>
									<template v-slot:cell(validationLevel)="data">
										<b-form-select
											v-model="data.item.validationLevel"
											:options="optionsForValidationLevel"
											@change="manageValidationChange(data.item)"
											id="valLevel"
											size="md"
										>
										</b-form-select>
									</template>
									<!-- slot-scope="data" -->
									<template slot="HEAD_isAdmin"> Admin Rights </template>
									<!-- <template slot="info" slot-scope="data"> -->
									<template v-slot:cell(info)="data">
										<a href="#" @click.prevent="rowClicked(data.item)">
											<component :is="getLucideIcon(ICONS.INFO.name)" :color="ICONS.INFO.color" :size="20" />
										</a>
									</template>
									<template v-slot:cell(permissions)="data">
										<div class="d-flex justify-content-center" style="min-width: 60px">
											<b-dropdown
												id="dropdown-offer-action"
												size="sm"
												:text="FormMSG(249, 'Permissions')"
												variant="warning"
												dropleft
												boundary="window"
												:class="lengthUsers <= 8 ? 'position-absolute' : ''"
											>
												<b-dropdown-item
													v-if="isForProject"
													@click="handleAccessLevel('canManageCrewList', !data.item.canManageCrewList, data.item, screenAccess)"
												>
													<b-form-checkbox size="sm" v-model="data.item.canManageCrewList">
														{{ FormMSG(2970, 'Manage crew list') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<b-dropdown-item
													v-if="isForProject && useDoc"
													@click="handleAccessLevel('canManageDocuments', !data.item.canManageDocuments, data.item, screenAccess)"
												>
													<b-form-checkbox size="sm" v-model="data.item.canManageDocuments">
														{{ FormMSG(2971, 'Manage documents') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<b-dropdown-item
													v-if="isForProject && useDoc"
													@click="
														handleAccessLevel(
															'canManageDocumentPackage',
															!data.item.canManageDocumentPackage,
															data.item,
															screenAccess
														)
													"
												>
													<b-form-checkbox size="sm" v-model="data.item.canManageDocumentPackage">
														{{ FormMSG(2440, 'Manage document package') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<b-dropdown-item
													v-if="isForProject && useCallSheet"
													@click="handleAccessLevel('canManageCallSheets', !data.item.canManageCallSheets, data.item, screenAccess)"
												>
													<b-form-checkbox size="sm" v-model="data.item.canManageCallSheets">
														{{ FormMSG(2450, 'Manage call sheets') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<b-dropdown-item
													v-if="isForProject && useAcc"
													@click="
														handleAccessLevel(
															'canManageAccommodations',
															!data.item.canManageAccommodations,
															data.item,
															screenAccess
														)
													"
												>
													<b-form-checkbox size="sm" v-model="data.item.canManageAccommodations">
														{{ FormMSG(2973, 'Manage accomodations') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<b-dropdown-item
													v-if="isForProject && useLoc"
													@click="handleAccessLevel('canManageLocations', !data.item.canManageLocations, data.item, screenAccess)"
												>
													<b-form-checkbox size="sm" v-model="data.item.canManageLocations">
														{{ FormMSG(2449, 'Manage locations') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<b-dropdown-item
													v-if="isForProject && useTrans"
													@click="handleAccessLevel('canManageTransport', !data.item.canManageTransport, data.item, screenAccess)"
												>
													<b-form-checkbox size="sm" v-model="data.item.canManageTransport">
														{{ FormMSG(24049, 'Manage transport') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<b-dropdown-item
													v-if="isForProject && useImportExport"
													@click="handleAccessLevel('canExportImport', !data.item.canExportImport, data.item, screenAccess)"
												>
													<b-form-checkbox size="sm" v-model="data.item.canExportImport">
														{{ FormMSG(2455, 'Can import/export') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<!-- <b-dropdown-item
													v-if="isForProject && useBudget"
													@click="
														handleAccessLevel(
															'canViewDepartmentBudget',
															!data.item.canViewDepartmentBudget,
															data.item,
															screenAccess
														)
													"
												>
													<b-form-checkbox size="sm" v-model="data.item.canViewDepartmentBudget">
														{{ FormMSG(2690, 'View department budget') }}
													</b-form-checkbox>
												</b-dropdown-item> -->
												<b-dropdown-item
													v-if="isForProject && useBudget"
													@click="handleAccessLevel('canViewGlobalBudget', !data.item.canViewGlobalBudget, data.item, screenAccess)"
												>
													<b-form-checkbox size="sm" v-model="data.item.canViewGlobalBudget">
														{{ FormMSG(24430, 'View global budget') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<b-dropdown-item
													v-if="isForProject && useGreenTable"
													@click="handleAccessLevel('canViewGreenTable', !data.item.canViewGreenTable, data.item, screenAccess)"
												>
													<b-form-checkbox size="sm" v-model="data.item.canViewGreenTable">
														{{ FormMSG(244030, 'View green table') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<b-dropdown-item
													v-if="isForProject && useReporting"
													@click="
														handleAccessLevel(
															'canAccessGreenReporting',
															!data.item.canAccessGreenReporting,
															data.item,
															screenAccess
														)
													"
												>
													<b-form-checkbox size="sm" v-model="data.item.canAccessGreenReporting">
														{{ FormMSG(24560, 'View Green reporting screens') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<b-dropdown-item
													v-if="isForProject && useCarbonRemoval"
													@click="
														handleAccessLevel('canManageCarbonRemoval', !data.item.canManageCarbonRemoval, data.item, screenAccess)
													"
												>
													<b-form-checkbox size="sm" v-model="data.item.canManageCarbonRemoval">
														{{ FormMSG(24480, 'Manage carbon removal') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<b-dropdown-item
													v-if="isForProject && useWaste"
													@click="handleAccessLevel('canViewWasteScreens', !data.item.canViewWasteScreens, data.item, screenAccess)"
												>
													<b-form-checkbox size="sm" v-model="data.item.canViewWasteScreens">
														{{ FormMSG(24481, 'View waste screens') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<b-dropdown-item
													v-if="isForProject && useTipOfDay"
													@click="handleAccessLevel('canManageTipOfTheDay', !data.item.canManageTipOfTheDay, data.item, screenAccess)"
												>
													<b-form-checkbox size="sm" v-model="data.item.canManageTipOfTheDay">
														{{ FormMSG(24405, 'Manage tip of the day') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<b-dropdown-item
													v-if="isForProject"
													@click="handleAccessLevel('canAccessConfig', !data.item.canAccessConfig, data.item, screenAccess)"
												>
													<b-form-checkbox size="sm" v-model="data.item.canAccessConfig">
														{{ FormMSG(246, 'Can access config') }}
													</b-form-checkbox>
												</b-dropdown-item>
											</b-dropdown>
										</div>
									</template>
									<template v-slot:cell(role)="data">
										<div class="d-flex justify-content-center" style="min-width: 60px">
											<b-dropdown
												id="dropdown-offer-action"
												size="sm"
												:text="FormMSG(2490, 'Roles')"
												variant="warning"
												dropleft
												boundary="window"
												:class="lengthUsers <= 8 ? 'position-absolute' : ''"
											>
												<b-dropdown-item
													v-if="isForProject"
													@click="handleAccessLevel(gateKeeper, !data.item.gateKeeper, data.item, isRole)"
												>
													<b-form-checkbox size="sm" v-model="data.item.gateKeeper">
														{{ FormMSG(266, 'Gate Keeper') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<b-dropdown-item
													v-if="isForProject"
													@click="handleAccessLevel(productionAccountant, !data.item.productionAccountant, data.item, isRole)"
												>
													<b-form-checkbox size="sm" v-model="data.item.productionAccountant">
														{{ FormMSG(261, 'Production Account') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<b-dropdown-item v-if="isForProject" @click="handleAccessLevel(upm, !data.item.upm, data.item, isRole)">
													<b-form-checkbox size="sm" v-model="data.item.upm">
														{{ FormMSG(262, 'UPM') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<b-dropdown-item
													v-if="isForProject"
													@click="handleAccessLevel(lineProducer, !data.item.lineProducer, data.item, isRole)"
												>
													<b-form-checkbox size="sm" v-model="data.item.lineProducer">
														{{ FormMSG(263, 'Line Producer') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<b-dropdown-item v-if="isForProject" @click="handleAccessLevel(studio, !data.item.studio, data.item, isRole)">
													<b-form-checkbox size="sm" v-model="data.item.studio">
														{{ FormMSG(265, 'Studio') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<b-dropdown-item
													v-if="isForProject"
													@click="handleAccessLevel(canValidateI9, !data.item.canValidateI9, data.item, screenAccess)"
												>
													<b-form-checkbox size="sm" v-model="data.item.canValidateI9">{{
														FormMSG(68, 'Can validate I-9')
													}}</b-form-checkbox>
												</b-dropdown-item>
											</b-dropdown>
										</div>
									</template>
								</b-table>
								<b-row>
									<b-col cols="12">
										<div class="nb-text">
											{{ FormMSG(300, 'Totals:') }} {{ globalUsers.length }} {{ FormMSG(301, 'of') }} {{ totalOfUsers }}
											{{ FormMSG(302, 'records') }}
										</div>
									</b-col>
								</b-row>
								<b-row v-if="useDpae" class="mt-3">
									<b-col cols="12">
										<b-button
											@click="sendDpae(filterContractIdInUserArray(selectedItem))"
											:disabled="selectedItem.length === 0"
											variant="primary"
										>
											{{ FormMSG(267, 'Send to DPAE') }} {{ selectedItem.length > 0 ? '(' + selectedItem.length + ')' : '' }}
										</b-button>
									</b-col>
								</b-row>
								<b-modal
									header-class="header-class-modal-doc-package"
									v-model="showUserModal"
									@close="refreshData"
									@cancel="refreshData"
									@ok="dispatchEvent"
									cancel-variant="light"
									:title="`${
										selectedUserId > 0
											? FormMSG(100, 'Edit User') + ' : ' + selectedUserData.firstName + ' ' + selectedUserData.name + ''
											: FormMSG(111, 'New User')
									}`"
									size="xxl"
									footer-class="w-100"
									centered
									no-close-on-backdrop
								>
									<b-container fluid v-if="$screen.width > 576">
										<b-row align-h="end">
											<b-col cols="2" style="display: none">
												<v-select
													id="user-status-selector"
													label="text"
													v-if="selectedUserId"
													v-model="currentUserStatus"
													:options="getStatusMenu"
													:reduce="(option) => option.value"
													@input="setUserStatus"
													class="w-75 border-radius-status"
												>
													<template #option="option">
														<component
															:is="getLucideIcon('Circle')"
															class="mr-3"
															:class="option.colorClass"
															:size="12"
															:stroke-width="1.5"
															style="border-radius: 100%"
														/>
														{{ option.text }}
													</template>
													<template #selected-option="{ text, colorClass }">
														<component
															:is="getLucideIcon('Circle')"
															class="mr-3"
															:class="colorClass"
															:size="12"
															:stroke-width="1.5"
															style="border-radius: 100%"
														/>
														{{ text }}
													</template>
												</v-select>
												<span v-else
													><component
														:is="getLucideIcon('Circle')"
														class="mr-3"
														:class="getStatusMenu[0].colorClass"
														:size="14"
														:stroke-width="1.5"
														style="border-radius: 100%"
													/>{{ getStatusMenu[0].text }}</span
												>
											</b-col>
											<b-col cols="auto" class="d-flex px-0">
												<b-form-group
													:label="FormMSG(333, 'User ID')"
													label-for="userId"
													label-cols="auto"
													content-cols="auto"
													:horizontal="true"
												>
													<b-form-input
														id="userId"
														:value="selectedUserData.id ? selectedUserData.id : ''"
														type="text"
														disabled
													></b-form-input>
												</b-form-group>
												<b-dropdown
													no-caret
													boundary="window"
													class="btn-transparent d-flex justify-content-end align-items-start"
													variant="none"
													size="sm"
													dropleft
													offset="35"
													lazy
													:disabled="true"
												>
													<template #button-content>
														<component :is="getLucideIcon('MoreVertical')" :size="25" :stroke-width="1.5" />
													</template>
													<b-dropdown-item>
														<div class="text-color-rhapsody-in-blue d-flex align-items-center">
															<component :is="getLucideIcon('Mail')" :size="16" />
															<div class="ml-2">{{ FormMSG(125, 'Email user') }}</div>
														</div>
													</b-dropdown-item>
													<b-dropdown-item>
														<div class="text-color-rhapsody-in-blue d-flex align-items-center">
															<component :is="getLucideIcon('Download')" :size="16" />
															<div class="ml-2">{{ FormMSG(2003, 'Download infosheet') }}</div>
														</div>
													</b-dropdown-item>
													<b-dropdown-item>
														<div class="text-color-rhapsody-in-blue d-flex align-items-center">
															<component :is="getLucideIcon('Trash')" :size="16" class="text-danger" />
															<div class="ml-2">{{ FormMSG(63, 'Delete user') }}</div>
														</div>
													</b-dropdown-item>
												</b-dropdown>
											</b-col>
										</b-row></b-container
									>
									<user-dialog
										:projectId="projectId"
										:selectedUserData="selectedUserData"
										:userId="selectedUserId"
										:is-saved="executeSaveAction"
										:display-add-btn="false"
										:reload-contracts="reloadContractsModal"
										v-on:closeForm="toggleUserModal"
										v-if="selectedUserId"
										:handleAccessLevel="(item, value, data, screenAccess) => handleAccessLevel(item, value, data, screenAccess)"
										@user-dialog:send-single-dpae="sendDpae"
										@user-dialog:dpae-counter-change="prepareDpaeToSendModal"
										@user-dialog:contracts-reloaded="reloadContractsModal = false"
										@user-dialog:user-data-change="setSaveButtonDisable = false"
										@user-dialog:user-saved="
											() => {
												(executeSaveAction = false), reloadData(true);
											}
										"
									/>
									<user-form :projectId="projectId" v-on:closeForm="toggleUserModal" @user-form:success="refreshData" v-else />
									<template #modal-footer="{ ok, cancel }" v-if="$screen.width > 576">
										<b-container fluid>
											<b-row align-h="between">
												<b-col cols="12" sm="auto">
													<b-button
														variant="light"
														class="px-4 btn-custom-outline-gray"
														@click="sendDpae(dpaeToSendModal)"
														:disabled="disableSendDpaeModal"
														v-if="useDpae"
													>
														{{ FormMSG(267, 'Send to DPAE') }}<span v-if="dpaeCounterModal > 0">({{ dpaeCounterModal }})</span>
													</b-button></b-col
												>
												<b-col cols="12" sm="auto" class="d-flex" style="gap: 10px">
													<b-button variant="light" @click="cancel()" class="px-4">
														{{ FormMSG(354, 'Cancel') }}
													</b-button>
													<b-button variant="secondary" @click="ok()" class="px-4" :disabled="setSaveButtonDisable">
														<b-spinner v-show="setSaveButtonDisable && executeSaveAction" small class="mr-3" />{{
															FormMSG(359, 'Save')
														}}
													</b-button>
												</b-col>
											</b-row></b-container
										>
									</template>
								</b-modal>
								<b-modal
									header-class="header-class-modal-doc-package"
									:title="FormMSG(62, 'Delete User?')"
									class="modal-success"
									v-model="successDeleteUserModal"
									@ok="confirmedDeleteUser()"
									ok-variant="primary"
									:ok-title="this.FormMSG(63, 'Delete user')"
								>
									<p>{{ this.emailOfUserToDelete }}</p>
								</b-modal>
								<b-modal
									header-class="header-class-modal-doc-package"
									:title="FormMSG(20, 'Picture')"
									class="modal-success"
									v-model="showContactPicture"
									@ok="showContactPicture = false"
									ok-variant="success"
									ok-only
								>
									<b-card v-for="(oneImage, i) in this.contactImagePath" :key="i">
										<b-container fluid class="p-4 bg-dark">
											<b-row>
												<b-col>
													<b-img fluid :src="oneImage"></b-img>
												</b-col>
											</b-row>
										</b-container>
									</b-card>
								</b-modal>
								<script-export-modal
									v-model="isScriptInfosheetOpen"
									:items="scripts"
									:custom="customReport"
									export-type="user"
									@script-export-modal:loading="handleLoading"
									@script-export-modal:closed="userRerpotOnClosed"
								/>
							</b-card>
						</b-tab>
						<b-tab v-if="canSeeUserSetting && !isForProjects && !isForGreenshotUser" lazy :title="FormMSG(1230, 'Onboarding')">
							<Views />
						</b-tab>
						<b-tab lazy v-if="canSeeDocPackage && !isForProjects && !isForGreenshotUser && useDoc" :title="FormMSG(1200, 'Document Package')">
							<DocumentPackageIndex />
						</b-tab>
					</b-tabs>
				</transition>
			</div>
		</div>
		<!-- DPAE message dialog -->
		<b-modal
			v-model="showDpaeMessage"
			size="md"
			header-class="header-class-modal-doc-package"
			header-bg-variant="light"
			:title="FormMSG(51, 'Error')"
			no-close-on-backdrop
			no-close-on-esc
			hide-header-close
		>
			<div class="d-flex align-items-center w-100" style="gap: 10px">
				<component class="text-danger" :is="getLucideIcon('XCircle')" /><span>{{ dpaeMsg }}</span>
			</div>
			<template #modal-footer>
				<div class="w-100">
					<b-row class="padding-c-1">
						<b-col cols="12">
							<div class="d-flex flex-row justify-content-end align-items-center w-100" style="gap: 20px">
								<b-button
									size="sm"
									variant="light"
									class="w-100-px d-flex justify-content-center align-items-center"
									@click="showDpaeMessage = false"
								>
									<span>OK</span>
								</b-button>
							</div>
						</b-col>
					</b-row>
				</div>
			</template>
		</b-modal>
		<!-- DPAE message dialog -->
	</errorContainer>
</template>

<script>
import { Switch as cSwitch } from '@coreui/vue';
import { store } from '@/store';
import { getObjectFromValue, isNil } from '~utils';
import dpaeMixin from '@/mixins/dpae.mixin';
import globalMixin from '@/mixins/global.mixin';
import UserDialog from './UserDialog';
// import User from './User';
import UserForm from './UserForm';
import DpaeTooltip from '@/components/DPAE/DpaeTooltip';
import languageMessages from '@/mixins/languageMessages';
import { getParamVersion } from '@/shared/version';
import axios from 'axios';
import { Search, X, ChevronLeft, MoreVertical, Trash2 as Delete } from 'lucide-vue';
import { ACCESS_LEVEL } from '@/utils/utils';
import DeterMixin from '@/mixins/deter.mixin';
import { DPAE_STATUS } from '@/shared/constants';
import {
	getUsers,
	addProd,
	delProd,
	addAdmin,
	delAdmin,
	addDev,
	delDev,
	addTranslator,
	delTranslator,
	signinAs,
	delUser,
	delUserRequest,
	delUserFromProject,
	delUserValidation,
	validate,
	canAssign,
	addUpdUpm,
	addUpdStudio,
	addUpdUserProjectAccessLevel,
	getCountTotalOfUser
} from '@/cruds/users.crud';
import { getProject } from '@/cruds/project.crud';
import ScriptExportModal from '@/components/Export/ScriptExportModal';
import { getScripts } from '@/cruds/script.crud';
import Views from '../../modules/onboarding/views/index.vue';
import DocumentPackageIndex from '../../modules/document-package/views/index.vue';
import ValidateDocuments from '../../modules/onboarding/views/ValidateDocuments.vue';
import { userReport } from '@/cruds/export.crud';
import _ from 'lodash';
import { FILENAME_REPORT, OUTPUT_FORMAT_EXPORT } from '@/shared/constants';
import { getFileExtension } from '@/shared/helpers';
import filterInputSearch from './filterInput.vue';
import activeTabMixin from '@/mixins/activeTab.mixin';
import RadioButton from 'primevue/radiobutton';

import moment from 'moment';
import { contracts, addUpdContractTemplate, sendDpaeService } from '@/cruds/contract.crud';

export default {
	name: 'Users',
	mixins: [languageMessages, globalMixin, DeterMixin, activeTabMixin, dpaeMixin],
	components: {
		cSwitch,
		UserForm,
		UserDialog,
		ChevronLeft,
		Search,
		X,
		ScriptExportModal,
		Views,
		DocumentPackageIndex,
		ValidateDocuments,
		MoreVertical,
		Delete,
		filterInputSearch,
		RadioButton,
		DpaeTooltip
	},
	props: {
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: false
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		},
		acceptFiles: {
			type: String,
			required: false,
			default: '.xlsx'
		},
		apiUploadUrl: {
			type: String,
			required: false,
			default: 'uploadImportFormatFile'
		},
		formFilename: {
			type: String,
			required: false,
			default: 'importFile'
		}
	},
	watch: {
		'tabs.activeTabs': {
			async handler(newVal) {
				this.tableUsers = await new Promise((resolve) => {
					setTimeout(() => {
						resolve(this.$refs['table-users']);
					}, 500);
				});
				if (this.canSeeApprove && this.canSeeUserSetting) {
					let _url = window.location.href.toString().split`#`[1];
					// if path is allUsers
					if (_url.includes('allUsers')) {
						if (newVal === 0) {
							if (this.tableUsers !== undefined) {
								this.tableUsers.$el.removeEventListener('scroll', this.handleScroll);
								this.tableUsers.$el.addEventListener('scroll', this.handleScroll);
							}
						}
					} else {
						if (newVal === 1) {
							if (this.tableUsers !== undefined) {
								this.tableUsers.$el.removeEventListener('scroll', this.handleScroll);
								this.tableUsers.$el.addEventListener('scroll', this.handleScroll);
							}
						}
					}

					return newVal;
				}
				if (this.canSeeApprove === false && this.canSeeUserSetting) {
					if (newVal === 0) {
						if (this.tableUsers !== undefined) {
							this.tableUsers.$el.removeEventListener('scroll', this.handleScroll);
							this.tableUsers.$el.addEventListener('scroll', this.handleScroll);
						}
					}

					return newVal;
				} else {
					if (newVal === 0) {
						if (this.tableUsers !== undefined) {
							this.tableUsers.$el.removeEventListener('scroll', this.handleScroll);
							this.tableUsers.$el.addEventListener('scroll', this.handleScroll);
						}
					}

					return newVal;
				}
			},
			immediate: true,
			deep: true
		},
		filter: {
			handler(newVal) {
				if (newVal.length === 0) {
					this.keyword = null;
					this.Users = this.globalUsers;
				}
				return newVal;
			},
			immediate: true,
			deep: true
		},
		'$route.query': {
			handler() {
				setTimeout(() => {
					if (this.$route.query.tabs) {
						this.handleTabsQuery();
					} else {
						this.tabs.activeTabs = 0;
					}
				}, 300);
			},
			// immediate: true,
			deep: true
		},
		usersListDisplayedBy: {
			async handler() {
				this.selectedItem = [];
				await this.reloadData();
			}
		}
	},
	mounted() {
		this.$nextTick(async () => {
			this.initilizeAccess();
			this.$root.$on('bv::modal::change', (isVisible, modalId) => {
				this.toggleUserModal();
			});
			await this.onHandleInitFilter();
			this.initTabs();
		});
		//console.log('users', this.globalUsers);
	},
	data: () => {
		let limited = Math.round(window.innerHeight / ((window.innerHeight * 5) / 100));

		const {
			isAdmin,
			isProd,
			isVip,
			canAssign,
			canAccessUsers,
			canAccessBudget,
			canAccessMyBudget,
			canAccessGreenReporting,
			canAccessConfig,
			canAccessOnboarding,
			canAccessCrewList,
			canValidateI9,
			gateKeeper,
			productionAccountant,
			upm,
			lineProducer,
			studio,
			useExpenseInvoice,
			canMarkEntryAsProcessed,
			canEditExpense,
			canEditPo,
			canDeleteAfterValidation,
			screenAccess,
			canLockBudget,
			canModifyEstimatedFinalCost,
			canEditAndAddCode,
			isRole,
			delValidationRole,
			addUpdValidationRole
		} = ACCESS_LEVEL;
		return {
			executeSaveAction: false,
			setSaveButtonDisable: true,
			currentValidationLevel: 0,
			hv: 'dark',
			isLoading: false,
			isAdmin,
			isProd,
			isVip,
			canAssign,
			canAccessUsers,
			canAccessBudget,
			canAccessMyBudget,
			canAccessGreenReporting,
			canAccessConfig,
			canAccessOnboarding,
			canAccessCrewList,
			canValidateI9,
			gateKeeper,
			productionAccountant,
			upm,
			lineProducer,
			studio,
			useExpenseInvoice,
			canMarkEntryAsProcessed,
			canEditExpense,
			canEditPo,
			canDeleteAfterValidation,
			canLockBudget,
			canModifyEstimatedFinalCost,
			canEditAndAddCode,
			screenAccess,
			isRole,
			delValidationRole,
			addUpdValidationRole,
			erreur: {},
			Users: [],
			currentPage: 1,
			perPage: 0,
			selectedUserId: null,
			showUserModal: false,
			successDeleteUserModal: false,
			idOfUserToDelete: 0,
			selectedUserData: {},
			emailOfUserToDelete: '',
			projectId: 0,
			filter: '',
			contactImagePath: [],
			showContactPicture: false,
			myUserId: 0,
			project: null,
			selectMode: 'single',
			selectedColor: 'primary',
			isScriptInfosheetOpen: false,
			infosheetTypeId: 7,
			scripts: [],
			ACCESS_LEVEL: {},
			isBusy: false,
			didUpdateRole: false,
			customReport: null,
			isLoadingDownload: false,
			page: limited,
			initPage: limited,
			isMore: false,
			limit: limited,
			offset: 0,
			keyword: null,
			globalUsers: [],
			totalOfUsers: 0,
			tableUsers: undefined,
			currentUserStatus: 0,
			// dpae
			showDpaeMessage: false,
			dpaeMsg: '',
			disableSendDpaeModal: true,
			dpaeCounterModal: 0,
			dpaeToSendModal: [],
			selectedItem: [],
			reloadContractsModal: false,
			usersListDisplayedBy: 'users',
			isAllItemSelected: false,
			useLicense: false,
			contractsList: [],
			DPAE_STATUS: DPAE_STATUS
		};
	},
	methods: {
		filterContractIdInUserArray(userList) {
			return userList.map((item) => item.contractId);
		},
		prepareDpaeToSendModal(contracts) {
			this.dpaeCounterModal = contracts.length;
			this.dpaeCounterModal > 0 ? (this.disableSendDpaeModal = false) : (this.disableSendDpaeModal = true);
			this.dpaeToSendModal = contracts.map((item) => item.id);
		},
		async sendDpae(listToSend) {
			this.disableSendDpaeModal = true;
			if (listToSend.lenght === 0) return;
			try {
				const isDpaeSend = await sendDpaeService(listToSend[0]);
				if (isDpaeSend) {
					this.createToastForMobile('Success', this.FormMSG(222, 'Sending to DPAE successfully, waiting for responses.'), '', 'success');
				} else {
					// this.createToastForMobile('Error', this.FormMSG(223, 'Some of the DPAE data is missing or wrong.'), '', '');
					this.dpaeMsg = this.FormMSG(223, 'Some of the DPAE data is missing or wrong.');
					this.showDpaeMessage = true;
				}
			} catch (error) {
				console.log(error);
				const err = this.resolveGqlError(error);
				// this.createToastForMobile('Error', err, '', 'danger');
				this.dpaeMsg = err;
				this.showDpaeMessage = true;
			} finally {
				if (_.isEqual(listToSend, this.dpaeToSendModal)) {
					this.reloadContractsModal = true;
				} else {
					console.log('data reloaded');
					this.reloadData();
				}
			}
			this.disableSendDpaeModal = false;
		},
		setUserStatus(status) {
			this.selectedUserData.status = status;
		},
		async onHandleInitFilter() {
			this.filter = '';
			this.keyword = null;
			await this.reloadData(false);
		},
		isDisabled(data) {
			return data.item.id == store.state.myProfile.id;
		},
		initilizeAccess() {
			this.ACCESS_LEVEL = ACCESS_LEVEL;
		},
		provideParameters() {
			let myVariables = {};
			let groupByContract = this.usersListDisplayedBy === 'users' ? false : true;
			// check for the project ID.
			let projNumber = null;

			if (this.isFromProject && this.$route.params.id) {
				projNumber = parseInt(this.$route.params.id, 10);
			} else if (this.isForProject) {
				const currentProject = store.getCurrentProjectConfig();
				if (currentProject.id) {
					projNumber = +currentProject.id;
				}
			}

			myVariables = {
				projectId: projNumber,
				myProject: false,
				limit: this.limit,
				offset: this.offset,
				keyword: this.keyword,
				groupByContract: groupByContract
			};

			return myVariables;
		},
		async filteredUsers(text) {
			// empty limit and offset
			this.limit = Math.round(window.innerHeight / ((window.innerHeight * 5) / 100));
			this.offset = 0;

			this.filter = text;
			let keyWord = this.filter.toString().replaceAll(/\s/gi, '%');
			this.keyword = keyWord;
			if (this.keyword.length !== 0) {
				// await this.$store.dispatch('refreshToken'); Lanja : Unknown use?

				// fetch Users data.
				this.isBusy = true;
				this.Users = [];
				const myVariables = this.provideParameters();
				this.totalOfUsers = await getCountTotalOfUser(myVariables.projectId, this.keyword);
				await getUsers(myVariables, myVariables.projectId)
					.then((records) => {
						this.Users = [...records];
						this.globalUsers = records;
						this.isBusy = false;
					})
					.catch((error) => {
						this.erreur = error;
						this.isBusy = false;
					});
				this.reassignSelectionToUserList();
				this.isBusy = false;
			}
		},
		reassignSelectionToUserList() {
			let selectedUserIds = [];
			if (this.usersListDisplayedBy === 'users') {
				selectedUserIds = this.selectedItem.map((user) => user.id); // Assuming there's an id property in users
			} else {
				selectedUserIds = this.selectedItem.map((user) => user.contractId);
			}
			this.Users = this.Users.map((user) => {
				let isSelected;
				if (this.usersListDisplayedBy === 'users') {
					isSelected = selectedUserIds.includes(user.id) ? true : false;
				} else {
					isSelected = selectedUserIds.includes(user.contractId) ? true : false;
				}
				return { ...user, isSelected }; // Assuming isSelected is a property of the user object
			});
		},
		async handleScroll(event) {
			if (this.globalUsers.length < this.totalOfUsers) {
				if (event.target.scrollTop) {
					if (Math.ceil(event.target.scrollTop + event.target.clientHeight + 2) >= event.target.scrollHeight) {
						if (!this.isMore) {
							if (event.target.scrollLeft) {
								this.isMore = false;
							} else {
								this.isMore = true;
							}
							this.offset += 1;
							setTimeout(async () => {
								await this.showLoader(this.handleGetUsers, 'containerUsers');
								this.isMore = false;
							}, 200);
						}
					}
				}
			}
		},
		onLoadMore() {
			if (this.filter.length !== 0) {
				this.isMore = false;
			} else {
				if (this.Users.length <= 10) {
					this.isMore = false;
				} else {
					this.isMore = true;
					setTimeout(() => {
						this.page += this.initPage;
						this.isMore = false;
					}, [200]);
				}
			}
		},
		async manageUpmChange(value, item, showToast = true) {
			try {
				await addUpdUpm({
					userId: item.id,
					Upm: value
				});

				item.upm = value;
				if (showToast) {
					this.createToastForSuccess();
				}
			} catch (error) {
				this.erreur = error;
			}
		},
		async manageStudioChange(value, item, showToast = true) {
			try {
				await addUpdStudio({
					userId: item.id,
					Studio: value
				});

				item.studio = value;
				if (showToast) {
					this.createToastForSuccess();
				}
			} catch (error) {
				this.erreur = error;
			}
		},
		/**
		 * @param {String} key
		 * @return {String}
		 */
		async handleGetUsers(isNewList = false) {
			// await this.$store.dispatch('refreshToken'); Lanja : Unknown use?
			const projNumber = parseInt(this.$route.params.id, 10);
			let defaultLimit = Math.round(window.innerHeight / ((window.innerHeight * 5) / 100));
			if (this.limit > defaultLimit) {
				this.offset = this.limit / defaultLimit;
			}

			this.limit = defaultLimit;
			// if (this.isFromProject) {
			// 	if (projNumber == 0) {
			// 		myVariables = {
			// 			myProject: true,
			// 			limit: this.limit,
			// 			offset: this.offset,
			// 			keyword: this.keyword
			// 		};
			// 	} else {
			// 		this.project = await getProject(projNumber);
			// 		myVariables = {
			// 			projectId: projNumber,
			// 			limit: this.limit,
			// 			offset: this.offset,
			// 			keyword: this.keyword
			// 		};
			// 	}
			// } else if (this.isForProject) {
			// 	myVariables = {
			// 		myProject: true,
			// 		limit: this.limit,
			// 		offset: this.offset,
			// 		keyword: this.keyword
			// 	};
			// } else {
			// 	myVariables = {
			// 		...myVariables,
			// 		myProject: null,
			// 		limit: this.limit,
			// 		offset: this.offset,
			// 		keyword: this.keyword
			// 	};
			// }

			// fetch Users data.
			const myVariables = this.provideParameters();

			await getUsers(myVariables, myVariables.projectId)
				.then((records) => {
					if (isNewList) {
						this.Users = records;
						this.Users.map((item) => {
							if (!item.isSelected) {
								item.isSelected = false;
							}
						});
						this.globalUsers = this.Users;
					} else {
						this.Users = [...this.Users, ...records];
						this.Users.map((item) => {
							if (!item.isSelected) {
								item.isSelected = false;
							}
						});
						this.globalUsers = this.Users;
					}
				})
				.catch((error) => {
					this.erreur = error;
				});
			this.reassignSelectionToUserList();
		},
		async reloadData(isNotCreated = true) {
			let defaultLimit = Math.round(window.innerHeight / ((window.innerHeight * 5) / 100));
			if (this.offset !== 0) {
				this.limit = this.limit * this.offset + defaultLimit;
			}
			this.offset = 0;
			if (isNotCreated) await this.$store.dispatch('refreshToken');

			// fetch Users data.
			const myVariables = this.provideParameters();
			this.isBusy = true;
			this.totalOfUsers = await getCountTotalOfUser(myVariables.projectId, this.keyword, this.useLicense, myVariables.groupByContract);
			try {
				let records = await getUsers(myVariables, myVariables.projectId);
				this.Users = records;
				if (this.keyword === null || this.keyword.length === 0) {
					this.globalUsers = records;
				}
				const { id } = store.state.myProfile;
				this.myUserId = parseInt(id);

				let userRequestsVariable = { projectId: 0 };
				if (this.isFromProject) {
					userRequestsVariable.projectId = parseInt(this.$route.params.id, 10);
				}

				this.reassignSelectionToUserList();
			} catch (error) {
				this.erreur = error;
			}
			this.isBusy = false;
		},
		getFieldLabel(key) {
			const item = getObjectFromValue(this.fields, 'key', key);
			return isNil(item) ? '' : item.label;
		},
		sendEmail(item) {
			var email = item.email;
			var subject = this.FormMSG(45, 'Subject');
			var emailBody =
				this.FormMSG(46, 'Hello') + ' ' + item.firstName + ',%0D%0A%0D%0A' + store.state.myProfile.firstName + ' ' + store.state.myProfile.name;
			document.location = 'mailto:' + email + '?body=' + emailBody + '&subject=' + subject;
		},
		callPhone(item) {
			window.location.href = 'tel://' + item.phone;
		},
		async downloadInfosheet(id) {
			id = +id;
			this.isLoadingDownload = true;
			this.warning = '';
			this.scripts = await getScripts(store.getCurrentProjectConfig().licenseID, store.getCurrentProjectConfig().id, this.infosheetTypeId, true);
			if (this.scripts.length === 0) {
				await this.manageInfosheet(id).finally(() => {
					this.isLoadingDownload = false;
				});
			} else if (this.scripts.length === 1) {
				await this.manageInfosheet(id, {
					id: this.scripts[0].id,
					fileName: this.scripts[0].customExportFileName,
					ext: this.getExtensionScript(
						OUTPUT_FORMAT_EXPORT.at(this.scripts[0].outputFileFormat),
						this.scripts[0].customExtension,
						getFileExtension(this.scripts[0].fileName)
					)
				}).finally(() => {
					this.isLoadingDownload = false;
				});
			} else {
				this.isScriptInfosheetOpen = true;
				this.customReport = {
					subjectId: id,
					sendEmail: false
				};
			}
		},
		showPicture(image) {
			// build path for picture
			this.contactImagePath = [];

			var fullPath = process.env.VUE_APP_GQL + '/images/' + image;
			this.contactImagePath.push(fullPath);
			this.showContactPicture = true;
		},
		goBack() {
			this.$router.go(-1);
		},
		deleteUser: function (item) {
			this.idOfUserToDelete = item.id;
			this.emailOfUserToDelete = item.email;
			this.successDeleteUserModal = true;
		},
		async deleteUserRequest(item) {
			this.$bvModal
				.msgBoxConfirm(this.FormMSG(64, 'Are you sure to delete this crew member invitation ?'), {
					title: this.FormMSG(65, 'Confirm'),
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: this.FormMSG(66, 'YES'),
					cancelTitle: this.FormMSG(67, 'NO'),
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then(async (v) => {
					if (!v) return;

					const userRequestId = parseInt(item.id, 10);
					await delUserRequest(userRequestId)
						.then(async (result) => {
							// force refresh
							await this.reloadData();
						})
						.catch((error) => {
							this.erreur = error;
						});
				})
				.catch((err) => console.log(err));
		},
		async confirmedDeleteUser() {
			const userId = parseInt(this.idOfUserToDelete, 10);
			await delUser(userId)
				.then((result) => {
					// force refresh
					window.location.reload(true);
				})
				.catch((error) => {
					this.erreur = error;
				});
		},
		eraseUser(user) {
			const action2 = async () => {
				await delUserFromProject(user.id).then((res) => {
					if (res) {
						this.createToastForMobile('Success', this.FormMSG(26540, 'User deleted successfully'), '', 'success');
						this.Users = this.Users.filter((u) => u.id !== user.id);
					} else {
						this.createToastForMobile('Warning', this.FormMSG(26545, 'There was a problem while trying to delete the user'), '', 'warning');
					}
				});
				await this.reloadData();
			};
			const action = async () => {
				this.confirmModal(
					action2,
					`${
						this.FormMSG(87445, 'Are you sure you want to delete the user') +
						' ' +
						user.fullName +
						'? ' +
						this.FormMSG(87490, 'This will erase all of the user associated data from the project')
					}`
				);
			};
			this.confirmModal(action, `${this.FormMSG(87410, 'Do you want to delete the user') + ' ' + user.fullName}?`);
		},
		okAfterUserEdit() {
			this.setSaveButtonDisable = true;
			this.executeSaveAction = true;
			//await this.reloadData();
		},
		async becomeUser(item) {
			const userId = parseInt(item.id, 10);
			await signinAs(userId)
				.then(async (result) => {
					const response = await store.onNewToken(this.$apollo.provider.defaultClient, result);
					if (response) {
						this.$router.go(0);
					}
				})
				.catch((error) => {
					this.erreur = error;
				});
		},
		toggleUserModal: function () {
			if (this.showUserModal) {
				this.selectedUserId = null;
			}
			this.showUserModal = !this.showUserModal;
		},
		selectUserId: function (userId) {
			this.selectedUserId = userId;
		},
		async addOrUpdateValidation(userId, departmentNumber, levelTs, levelPo, levelExp) {
			const replace = true;
			const projectId = parseInt(store.projectID(), 10);
			await validate({
				userId,
				departmentNumber,
				levelTs,
				levelPo,
				levelExp,
				projectId,
				replace
			})
				.then((result) => {})
				.catch((error) => {
					this.erreur = error;
				});
		},

		getRowCount(items) {
			return items.length;
		},
		userLink(id) {
			if (this.isForProject) {
				return `/project/users/${id.toString()}`;
			} else {
				return `/users/${id.toString()}`;
			}
		},
		rowClicked(item) {
			this.selectedUserData = Object.assign({}, item);
			if (!this.selectedUserData.status) this.selectedUserData.status = 0;
			this.currentUserStatus = this.selectedUserData.status;
			const userId = item.id.toString();
			this.selectUserId(userId);
			this.toggleUserModal();
		},
		newUser() {
			this.selectedUserId = null;
			if (this.isFromProject) {
				this.projectId = parseInt(this.$route.params.id, 10);
			}
			this.toggleUserModal();
		},
		rowClass(item, type) {
			if (!item) return;
			if (item.isDev && store.isDev()) return 'table-warning'; // store.isDev() : les "spectateurs" non isDev ne doivent pas savoir qui est dev.
			if (item.isProd) return 'table-primary'; // quand isProd regarde les users du projet
			if (item.isAdmin) return 'table-success';
		},
		async makeProd(id) {
			const userId = parseInt(id, 10);
			var myVariables = {};
			if (this.isFromProject) {
				const projectId = parseInt(this.$route.params.id, 10);
				myVariables = {
					userId,
					projectId
				};
			} else {
				myVariables = {
					userId
				};
			}
			await addProd(myVariables)
				.then((result) => {})
				.catch((error) => {
					this.erreur = error;
				});
		},
		async handleAccessLevel(accessname, value, item, ...args) {
			function setAccess(keyName, value) {
				const obj = {};
				obj[keyName] = value;
				return obj;
			}
			let response = false;
			if (args[0] == this.isRole) {
				item[accessname] = value;
				if (+item.id == store.userID()) {
					store.state.myProfile[accessname] = value;
				}
				if (Object.values(setAccess(accessname, value))[0] === false) {
					let payload = {
						ValidationId: 0,
						UserId: +item.id,
						ProjectId: +store.getCurrentProjectConfig().id,
						ValLvlDoc:
							accessname === this.gateKeeper
								? 5
								: accessname === this.productionAccountant
								? 6
								: accessname === this.upm
								? 7
								: accessname === this.lineProducer
								? 8
								: 9
					};
					response = await addUpdUserProjectAccessLevel(payload, this.isRole, this.delValidationRole);
				} else {
					let payload = {
						ValidationId: 0,
						UserId: +item.id,
						ProjectId: +store.getCurrentProjectConfig().id,
						UpdValidation: {
							department: -1,
							type: 1,
							validationLevelDoc:
								accessname === this.gateKeeper
									? 5
									: accessname === this.productionAccountant
									? 6
									: accessname === this.upm
									? 7
									: accessname === this.lineProducer
									? 8
									: 9
						}
					};
					response = await addUpdUserProjectAccessLevel(payload, this.isRole, this.addUpdValidationRole);
				}
			}
			if (args[0] === this.screenAccess) {
				item[accessname] = value;
				let payload = {
					userId: +item.id,
					projectId: +store.getCurrentProjectConfig().id,
					...setAccess(accessname, value)
				};
				response = await addUpdUserProjectAccessLevel(payload, this.screenAccess);
			}

			item[accessname] = value;
			if (+item.id === store.userID()) {
				await this.$store.dispatch('refreshToken');
			}
			return response && this.createToastForSuccess();
		},
		manageIsDevChange(value, item) {
			if (value) {
				this.makeIsDev(item.id);
			} else {
				this.delIsDev(item.id);
			}
			item.isDev = value;
			this.createToastForSuccess();
		},
		manageIsTranslatorChange(value, item) {
			if (value) {
				this.makeIsTranslator(item.id);
			} else {
				this.delIsTranslator(item.id);
			}

			item.isTranslator = value;
			this.createToastForSuccess();
		},
		async manageValidationChange(item) {
			if (item.validationLevel === 0) {
				await delUserValidation(item.id);
			} else {
				this.addOrUpdateValidation(
					parseInt(item.id),
					parseInt(item.department),
					parseInt(item.validationLevel),
					parseInt(item.validationLevel),
					parseInt(item.validationLevel)
				);
			}
		},
		async delProd(id) {
			const userId = parseInt(id, 10);
			var myVariables = {};
			if (this.isFromProject) {
				const projectId = parseInt(this.$route.params.id, 10);
				myVariables = {
					userId,
					projectId
				};
			} else {
				myVariables = {
					userId
				};
			}
			await delProd(myVariables)
				.then((result) => {})
				.catch((error) => {
					this.erreur = error;
				});
		},
		async makeAdmin(id) {
			const userId = parseInt(id, 10);
			await addAdmin(userId)
				.then((result) => {})
				.catch((error) => {
					this.erreur = error;
				});
		},
		async delAdmin(id) {
			const userId = parseInt(id, 10);
			await delAdmin(userId)
				.then((result) => {})
				.catch((error) => {
					this.erreur = error;
				});
		},
		async makeIsDev(id) {
			const userId = parseInt(id, 10);
			await addDev(userId)
				.then((result) => {})
				.catch((error) => {
					this.erreur = error;
				});
		},
		async delIsDev(id) {
			const userId = parseInt(id, 10);
			await delDev(userId)
				.then((result) => {})
				.catch((error) => {
					this.erreur = error;
				});
		},
		async makeIsTranslator(id) {
			const userId = parseInt(id, 10);
			await addTranslator(userId)
				.then((result) => {})
				.catch((error) => {
					this.erreur = error;
				});
		},
		async delIsTranslator(id) {
			const userId = parseInt(id, 10);
			await delTranslator(userId)
				.then((result) => {})
				.catch((error) => {
					this.erreur = error;
				});
		},
		// async importXls(e) {
		// 	this.isLoading = true;
		// 	const file = e.target.files[0];
		// 	let formData = new FormData();
		// 	formData.append(this.formFilename, file);
		// 	formData.append('fileName', file.name);
		// 	formData.append('fileType', file.type);
		// 	formData.append('importFormatType', 0);
		// 	const urlStr = process.env.VUE_APP_GQL + '/' + this.apiUploadUrl;
		// 	await axios
		// 		.post(urlStr, formData, {
		// 			headers: {
		// 				Authorization: `Bearer ${store.state.token}`,
		// 				'Content-Type': 'multipart/form-data'
		// 			}
		// 		})
		// 		.then(async (record) => {
		// 			// console.log('data', record);
		// 			await this.reloadData();
		// 		})
		// 		.catch((e) => {
		// 			console.error({ e });
		// 		})
		// 		.finally(() => {
		// 			this.isLoading = false;
		// 		});
		// },
		createToastForSuccess() {
			this.createToastForMobile('Success', this.FormMSG(260, 'Update permissions successfully'), '', 'success');
		},
		async refreshData() {
			await this.reloadData();
			this.showUserModal = false;
		},
		dispatchEvent(e) {
			e.preventDefault();
			this.okAfterUserEdit();
			if (!isNil(this.$refs['new-contract'])) {
				this.$refs['new-contract'].$refs['new-contract'].click();
			}
		},
		async manageInfosheet(subjectId, script = { id: 0, fileName: null, ext: 'xlsx' }, sendEmail = false) {
			let fileName = this.getFileNameScript(FILENAME_REPORT.REPORT_USER, script.id, script.fileName);

			await userReport(
				{
					subjectId,
					sendEmail
				},
				script.id
			)
				.then((result) => {
					if (!sendEmail) {
						this.forceDownload(result, fileName + '.' + script.ext);
					}
				})
				.catch((e) => {
					console.error(e);
				});
		},
		handleLoading(payload) {
			if (payload) {
				this.isLoadingDownload = false;
			}
		},
		userRerpotOnClosed() {
			this.isScriptInfosheetOpen = false;
			this.isLoadingDownload = false;
		},
		selectItem(item) {
			if (item.isSelected === true) {
				this.selectedItem.push(item);
			} else {
				const index = this.selectedItem.findIndex((selected) => selected.id === item.id);
				if (index !== -1) {
					this.selectedItem.splice(index, 1);
				}
			}
		},
		selectAllItem() {
			if (this.isAllItemSelected === true) {
				this.Users.forEach((user) => {
					if (this.isSendableStatusDpae(user.contractDpaeStatus)) {
						user.isSelected = true;
					}
				});
				this.selectedItem = this.Users.filter((user) => user.isSelected);
				//this.reassignSelectionToUserList();
			} else {
				this.Users.map((item) => (item.isSelected = false));
				this.selectedItem = [];
			}
		},
		unSelectAllItem() {
			this.selectedItem = [];
		},
		unSelectItem(user) {
			if (this.usersListDisplayedBy === 'users') {
				const newList = this.selectedItem.filter((item) => {
					return +item.id !== +user.data.id;
				});
				if (newList) {
					this.selectedItem = newList;
				}
			} else {
				const newList = this.selectedItem.filter((item) => {
					return +item.contractId !== +user.data.contractId;
				});
				if (newList) {
					this.selectedItem = newList;
				}
			}
		}
	},
	computed: {
		useCarbonTemplate() {
			return store.getCurrentProjectConfig().useCarbonTemplate;
		},
		useGreenTable() {
			return store.getCurrentProjectConfig().useGreenTable;
		},
		useReporting() {
			return store.getCurrentProjectConfig().useReporting;
		},
		useCarbonRemoval() {
			return store.getCurrentProjectConfig().useCarbonRemoval;
		},
		useWaste() {
			return store.getCurrentProjectConfig().useWaste;
		},
		useTipOfDay() {
			return store.getCurrentProjectConfig().useTipOfDay;
		},
		useBudget() {
			return store.getCurrentProjectConfig().useBudget;
		},
		useImportExport() {
			return store.getCurrentProjectConfig().useImport || store.getCurrentProjectConfig().useExport;
		},
		useTrans() {
			return store.getCurrentProjectConfig().useTransport;
		},
		useLoc() {
			return store.getCurrentProjectConfig().useLocations;
		},
		useAcc() {
			return store.getCurrentProjectConfig().useAccommodation;
		},
		useCallSheet() {
			return store.getCurrentProjectConfig().useCallSheet;
		},
		useDoc() {
			// return store.getCurrentProjectConfig().useDocument;
			// return await store.getCurrentProjectConfig().useDocument;
			return store.getCurrentProjectConfig().useDocument;
		},
		needValidation() {
			return (
				store.getCurrentProjectConfig().useExpense || store.getCurrentProjectConfig().usePurchaseOrder || store.getCurrentProjectConfig().useTimesheet
			);
		},
		isForGreenshotUser() {
			return this.$route.path.includes('allUsers');
		},
		isFromProject() {
			if (this.$route.params.id === undefined) {
				return false;
			} else {
				return true;
			}
		},
		isForProject() {
			if (this.$route.path.includes('project')) {
				return true;
			} else {
				return false;
			}
		},
		isForProjects() {
			if (this.$route.path.includes('projects')) {
				return true;
			} else {
				return false;
			}
		},
		canSeeApprove() {
			if (
				store.state.myProfile.upm ||
				store.state.myProfile.gateKeeper ||
				store.state.myProfile.productionAccountant ||
				store.state.myProfile.lineProducer ||
				store.state.myProfile.studio ||
				store.state.myProfile.canValidateI9
			) {
				return true;
			} else {
				return false;
			}
		},
		canSeeUserSetting() {
			return store.canManageCrewList();
		},
		canSeeDocPackage() {
			return store.canManageDocumentPackage();
			// return store.jwtData().TokenData.CanManageDocuments;
		},
		lengthUsers() {
			return this.Users.length;
		},
		isPme() {
			return store.isPme();
		},
		isFilm() {
			return store.isFilm();
		},
		optionsForValidationLevel() {
			return this.FormMenu(1113);
		},
		fields() {
			let ret = [];
			if (this.isForProject) {
				ret = [
					// {key: 'id'},
					{
						label: this.FormMSG(6, 'No.'),
						key: 'id',
						sortable: true,
						tdClass: 'align-middle'
					},
					{
						label: this.FormMSG(369, 'Status'),
						key: 'isMandatoryFieldComplete',
						sortable: true,
						tdClass: 'align-middle'
					},
					{
						label: this.FormMSG(20, 'Last name'),
						key: 'name',
						sortable: true,
						tdClass: 'align-middle'
					},
					{
						label: this.FormMSG(21, 'First name'),
						key: 'firstName',
						sortable: true,
						tdClass: 'align-middle'
					},
					{
						key: 'departmentName',
						label: this.FormMSG(23, 'Department'),
						sortable: true,
						formatter: (v) => v,
						tdClass: 'align-middle'
					},
					{
						key: 'functionName',
						label: this.FormMSG(58, 'Position'),
						sortable: true,
						formatter: (v) => v,
						tdClass: 'align-middle'
					}
				];
				if (this.useDpae) {
					ret.unshift({
						label: '',
						key: 'actionSelected',
						class: 'text-center align-middle',
						sortable: false,
						thStyle: { width: '70px' },
						tdClass: 'align-middle'
					});
					ret.push({
						key: 'contractDpaeStatus',
						label: this.FormMSG(59, 'DPAE status'),
						sortable: true,
						formatter: (v) => v,
						tdClass: 'align-middle'
					});
				}
				ret = [
					...ret,
					{
						key: 'contractStartDate',
						label: this.FormMSG(56, 'Start Date'),
						sortable: true,
						formatter: (v) => moment(v).format('DD/MM/YYYY'),
						tdClass: 'align-middle'
					},
					{
						key: 'contractEndDate',
						label: this.FormMSG(57, 'End Date'),
						sortable: true,
						formatter: (v) => moment(v).format('DD/MM/YYYY'),
						tdClass: 'align-middle'
					}
				];
				if (!this.isForProjects) {
					/*ret.push({
						key: 'permissions',
						label: this.FormMSG(227, 'Screen Access'),
						sortable: false,
						formatter: (v) => v,
						class: 'text-center'
					});
					if (this.useDoc) {
						ret.push({
							key: 'role',
							label: this.FormMSG(277, 'Role'),
							formatter: (v) => v,
							class: 'text-center'
						});
					}
					if (this.needValidation) {
						ret.push({
							key: 'validationLevel',
							label: this.FormMSG(27, 'Validation rights'),
							sortable: true,
							formatter: (v) => v,
							class: 'text-center'
						});
					}*/
				}
				ret.push({
					key: 'pict',
					label: this.FormMSG(35, 'Actions'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center',
					tdClass: 'align-middle'
				});
			} else {
				ret = [
					// {key: 'id'},
					{
						label: this.FormMSG(20, 'Last name'),
						key: 'name',
						sortable: true,
						tdClass: 'align-middle'
					},
					{
						label: this.FormMSG(21, 'First name'),
						key: 'firstName',
						sortable: true,
						tdClass: 'align-middle'
					},
					{
						label: this.FormMSG(22, 'Email'),
						key: 'email',
						sortable: true,
						tdClass: 'align-middle'
					},
					{
						label: this.FormMSG(29, 'Phone'),
						key: 'phone',
						sortable: true,
						tdClass: 'align-middle'
					},
					{
						key: 'isDev',
						label: this.FormMSG(42, 'View TheGreenShot Menu'),
						sortable: true,
						formatter: (v) => v,
						class: 'text-center',
						tdClass: 'align-middle'
					},
					{
						key: 'isTranslator',
						label: this.FormMSG(43, 'Translator'),
						sortable: true,
						formatter: (v) => v,
						class: 'text-center',
						tdClass: 'align-middle'
					},
					{
						key: 'Impersonate',
						label: this.FormMSG(28, 'Impersonate'),
						sortable: false,
						formatter: (v) => v,
						class: 'text-center',
						tdClass: 'align-middle'
					},
					{
						key: 'delUser',
						label: this.FormMSG(39, 'Delete'),
						sortable: false,
						formatter: (v) => v,
						class: 'text-center',
						tdClass: 'align-middle'
					}
				];
			}

			return ret;
		},
		Mobilefields() {
			let ret = [];
			if (this.isForProject) {
				ret = [
					// {key: 'id'},
					{
						label: this.FormMSG(4, 'Ref.'),
						key: 'reference',
						sortable: true
					},
					{
						label: this.FormMSG(20, 'Last name'),
						key: 'name',
						sortable: true
					},
					{
						label: this.FormMSG(21, 'First name'),
						key: 'firstName',
						sortable: true
					},
					{
						key: 'departmentName',
						label: this.FormMSG(23, 'Department'),
						sortable: true,
						formatter: (v) => v
					},
					{
						key: 'functionName',
						label: this.FormMSG(24, 'Function'),
						sortable: true,
						formatter: (v) => v
					}
				];
				ret.push({
					key: 'pict',
					label: this.FormMSG(35, 'Actions'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				});
			} else {
				ret = [
					// {key: 'id'},
					{
						label: this.FormMSG(20, 'Last name'),
						key: 'name',
						sortable: true
					},
					{
						label: this.FormMSG(21, 'First name'),
						key: 'firstName',
						sortable: true
					},
					{
						label: this.FormMSG(22, 'Email'),
						key: 'email',
						sortable: true
					},
					{
						label: this.FormMSG(29, 'Phone'),
						key: 'phone',
						sortable: true
					},
					{
						key: 'isDev',
						label: this.FormMSG(42, 'View TheGreenShot Menu'),
						sortable: true,
						formatter: (v) => v,
						class: 'text-center'
					},
					{
						key: 'isTranslator',
						label: this.FormMSG(43, 'Translator'),
						sortable: true,
						formatter: (v) => v,
						class: 'text-center'
					},
					{
						key: 'Impersonate',
						label: this.FormMSG(28, 'Impersonate'),
						sortable: false,
						formatter: (v) => v,
						class: 'text-center'
					},
					{
						key: 'delUser',
						label: this.FormMSG(39, 'Delete'),
						sortable: false,
						formatter: (v) => v,
						class: 'text-center'
					}
				];
			}

			return ret;
		},
		userRequestFields() {
			var ret = [];
			ret = [
				// {key: 'id'},
				{
					label: this.FormMSG(20, 'Last name'),
					key: 'userName',
					sortable: true
				},
				{
					label: this.FormMSG(21, 'First name'),
					key: 'userFirstName',
					sortable: true
				},
				{
					label: this.FormMSG(22, 'Email'),
					key: 'email',
					sortable: true
				},
				{
					key: 'createdAt',
					label: this.FormMSG(54, 'Sent on'),
					sortable: true,
					formatter: (value) => {
						var str =
							value.split('T')[0].substring(8) +
							'/' +
							value.split('T')[0].substring(5, 7) +
							' ' +
							value.split('T')[1].substring(0, 2) +
							':' +
							value.split('T')[1].substring(3, 5);
						if (str == '01/01 00:00') {
							return '';
						} else {
							return str;
						}
					}
				},
				{
					key: 'departmentName',
					label: this.FormMSG(23, 'Department'),
					sortable: true,
					formatter: (v) => v
				},
				{
					key: 'functionName',
					label: this.FormMSG(24, 'Function'),
					sortable: true,
					formatter: (v) => v
				},
				{
					key: 'delUserRequest',
					label: this.FormMSG(39, 'Delete'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				}
			];
			return ret;
		},
		isDev() {
			return store.isDev();
		}
	}
};
</script>
<!--<style>
.dropdown-menu {
	z-index: 1 !important;
}
</style>-->
<style scoped>
.nb-text {
	font-weight: 600 !important;
	font-size: 14px !important;
	letter-spacing: 0.01em !important;
	color: rgba(6, 38, 62, 0.74) !important;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}

.missing-info-text {
	color: rgba(117, 102, 31, 0.74) !important;
	font-style: italic;
}
</style>
